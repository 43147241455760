import { Link } from "gatsby"
import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"

const IndexPage: React.FC = () => (
  <Layout activePage="home" showCarousel>
    <SEO title="Home" />

    <h1 className="page-title">Welcome to Greenfield Pottery.</h1>
    <div className="divider divider--spacer-bottom" />
    <p>
      We are a working studio pottery based on a small farm on the outskirts of
      Holbeach, near Spalding in Lincolnshire. We make a variety of thrown and
      hand-built work, which varies from domestic wares to more sculptural
      pieces. We fire to earthenware and stoneware, we make our own glazes and
      we also do raku and sawdust firings. We also run pottery classes and
      sessions in our home studio throughout the year.
    </p>
  </Layout>
)

export default IndexPage
